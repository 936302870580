import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppButton from '../../app-button/AppButton';
import XCloseIcon from '../../icons/XCloseIcon';
import { ReactComponent as ShareIcon } from '../../../../assets/images/share-icon.svg';
import './TimerShare.scss';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { baseUrl } from '../../../../app/auth/auth.utils';
import { useAppSelector } from '../../../../app/store';

interface IProps {
  className?: string;
}

export const TimerShareDropdown = ({ className = '', ...attributes }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { activeTimer: timer } = useAppSelector((store) => store.StageTimerReducer);
  const timerId = timer?.id;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCopyLink = async () => {
    try {
      const shareUrl = `${baseUrl}/timer/${timerId}`;

      await navigator.clipboard.writeText(shareUrl);

      // Close the dropdown first
      setIsOpen(false);

      // Then show the toast
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } catch (error) {
      ApplicationInsightsApi.trackTrace('Failed to copy link', { error });
      ApplicationInsightsApi.trackException(error);
    }
  };

  const handleToggleDropdown = () => {
    // Hide toast if it's showing when opening the dropdown
    if (showToast) {
      setShowToast(false);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`timer-share-dropdown ${isOpen ? 'timer-share-dropdown--open' : ''}`}
      {...attributes}
    >
      <AppButton
        id="timer-share"
        className="timer-share-toggle-button"
        onClick={handleToggleDropdown}
      >
        <span>{t('studyWithFriends')}</span>
        <ShareIcon className="timer-share-icon" />
      </AppButton>

      <div className="timer-share-menu" ref={dropdownRef}>
        <div className="timer-share-header">
          <span>{t('studyWithFriends')}</span>
          <ShareIcon className="timer-share-icon" />
          <AppButton
            id="timer-share-close-button"
            className="timer-share-close-button"
            onClick={() => setIsOpen(false)}
          >
            <XCloseIcon />
          </AppButton>
        </div>
        <AppButton
          id="timer-share-copy-link-button"
          className="timer-share-copy-link-button"
          onClick={handleCopyLink}
        >
          {t('copyWebLink')}
        </AppButton>
      </div>

      {showToast && <div className="timer-share-toast">{t('shareLinkCopiedToast')}</div>}
    </div>
  );
};
