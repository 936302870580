import { Fragment, useEffect, useRef, useState } from 'react';
import TimerCircle from './TimerCircle/TimerCircle';
import './Timer.scss';
import { useTranslation } from 'react-i18next';
import TimerControls from './TimerControls/TimerControls';
import { ETimerStatus } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.interface';
import {
  activeTimerIdLocalStorageKey,
  createTimerReqAction,
  getTimerReqAction,
  pauseTimerReqAction,
  setActiveTimer,
  setTimerTask,
  timerTaskLocalStorageKey,
} from '../../../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.store';
import { ApplicationInsightsApi } from '../../../application-insights';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import removeAppOverlayPopover from '../app-overlay-popover/removeAppOverlayPopover';
import AppButton from '../app-button/AppButton';
import { EAPIStatus, IAPIError } from '../../api/models';
import { getItemFromLocalStorage } from '../../utils/localStorage.utils';
import {
  ETaskFormType,
  IMessageDataTask,
} from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { useSearchParams } from 'react-router-dom';
import {
  setShouldOpenWorkBlockDetails,
  setWorkBlockForEdit,
} from '../../../features/chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import {
  setSelectedMainTaskForEditing,
  setShouldOpenAddEditTaskFrom,
} from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';

interface TimerProps {
  timerId?: string;
  radius?: number;
  showCompletedAsText?: boolean;
  shouldShowControls?: boolean;
  shouldUsePolling?: boolean;
}

const defaultDuration = 30 * 60;

const Timer: React.FC<TimerProps> = ({
  timerId,
  radius = 140,
  showCompletedAsText = false,
  shouldShowControls = false,
  shouldUsePolling = false,
}) => {
  const { t } = useTranslation();
  const { activeTimer, createTimerRes, timerTask } = useAppSelector(
    (store) => store.StageTimerReducer,
  );
  const [localRemainingTime, setLocalRemainingTime] = useState<number>(
    activeTimer?.remainingTimeInSeconds || 0,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const isCompleted = localRemainingTime <= 0;
  const isRunning = activeTimer?.status === ETimerStatus.RUNNING;
  const FULL_CIRCLE_ARC = 900;
  const MIN_ARC_LENGTH = 0;
  // Refs for intervals
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  // Constants
  const TIMER_INTERVAL_MS = 1000;
  // Refs for intervals
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Constants
  const POLLING_INTERVAL_MS = 5000; // Poll server every 5 seconds

  const handleTimerNameClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (shouldUsePolling || activeTimer?.hasError) return;
    e.preventDefault();
    if (timerTask?.isWorkBlock) {
      dispatch(setWorkBlockForEdit(timerTask));
      dispatch(setShouldOpenWorkBlockDetails(true));
    } else {
      dispatch(setSelectedMainTaskForEditing(timerTask));
      dispatch(
        setShouldOpenAddEditTaskFrom(timerTask?.isEvent ? ETaskFormType.Event : ETaskFormType.Task),
      );
    }
  };

  const calculateArcLength = (): number => {
    const totalTime = activeTimer?.totalDurationInSeconds || 1;
    const ratio = 1 - localRemainingTime / totalTime;
    const clampedRatio = Math.min(1, Math.max(0, ratio));
    const arcLength = FULL_CIRCLE_ARC * clampedRatio;
    if (isCompleted) {
      return FULL_CIRCLE_ARC;
    } else if (createTimerRes.status === EAPIStatus.PENDING) {
      return MIN_ARC_LENGTH;
    } else {
      return isNaN(arcLength) ? FULL_CIRCLE_ARC : arcLength;
    }
  };

  const formatTime = (seconds: number): string => {
    if (isCompleted) {
      return showCompletedAsText ? t('timerEndedDescription') : '00:00';
    }
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().split('.')[0].padStart(2, '0')}`;
  };

  // Sync timer with server
  const syncTimerWithServer = async () => {
    try {
      const serverTimer = await dispatch(
        getTimerReqAction(timerId || activeTimer?.id || ''),
      ).unwrap();

      if (serverTimer?.status === ETimerStatus.RUNNING && serverTimer?.endTime) {
        const remaining = serverTimer.remainingTimeInSeconds;

        // Only update if there's a significant difference
        const timeDiff = Math.abs(remaining - localRemainingTime);
        if (timeDiff > 2 || serverTimer.status !== activeTimer?.status) {
          setLocalRemainingTime(remaining);
        }
      } else {
        if (
          Math.abs(serverTimer.remainingTimeInSeconds - localRemainingTime) > 2 ||
          serverTimer.status !== activeTimer?.status
        ) {
          setLocalRemainingTime(serverTimer.remainingTimeInSeconds);
        }
      }
    } catch (error) {
      ApplicationInsightsApi.trackTrace('Error syncing timer with server');
      ApplicationInsightsApi.trackException(error);
    }
  };

  // Visibility change handler
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && activeTimer?.status === ETimerStatus.RUNNING) {
      syncTimerWithServer();
    }
  };

  // Initialize timer on mount
  useEffect(() => {
    const initializeTimer = async () => {
      const storedActiveTimerId = getItemFromLocalStorage<string>(activeTimerIdLocalStorageKey);
      const storedTimerTask = getItemFromLocalStorage<IMessageDataTask>(timerTaskLocalStorageKey);
      const timerTaskId = timerTask?.id || storedTimerTask?.id;
      if (!timerTaskId) return;
      if (storedActiveTimerId && storedTimerTask) {
        dispatch(getTimerReqAction(storedActiveTimerId))
          .then(() => dispatch(setTimerTask(storedTimerTask)))
          .catch((error: Error) => {
            ApplicationInsightsApi.trackException(error);
          });
      } else if (timerTask && timerTask.id) {
        dispatch(
          createTimerReqAction({
            name: timerTask.name,
            durationInSeconds: timerTask.duration || defaultDuration,
            userTaskId: timerTask.id,
          }),
        )
          .unwrap()
          .catch((error: Error) => {
            ApplicationInsightsApi.trackException(error);
          });
      } else {
        ApplicationInsightsApi.trackTrace('Timer - initializeTimer - unable to initialize timer');
        ApplicationInsightsApi.trackException(
          new Error('Timer - initializeTimer - unable to initialize timer'),
        );
        setSearchParams({ stageContent: searchParams.get('from') || '' });
      }
    };

    initializeTimer();
  }, []);

  // Update local time when timer state changes
  useEffect(() => {
    if (activeTimer) {
      if (activeTimer.status === ETimerStatus.RUNNING) {
        const remaining = activeTimer.remainingTimeInSeconds;
        setLocalRemainingTime(remaining);
      } else {
        setLocalRemainingTime(
          activeTimer.remainingTimeInSeconds !== undefined
            ? activeTimer.remainingTimeInSeconds
            : defaultDuration,
        );
      }
    }
  }, [activeTimer]);

  // Fetch timer data from store
  const fetchTimerData = () => {
    if (!timerId) return;
    dispatch(getTimerReqAction(timerId))
      .unwrap()
      .catch((error: IAPIError) => {
        if (error.code === 404) {
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
          }
        }
      });
  };

  useEffect(() => {
    if (shouldUsePolling) {
      fetchTimerData();

      // Set up polling interval
      pollingIntervalRef.current = setInterval(fetchTimerData, POLLING_INTERVAL_MS);
    }

    // Clean up on unmount
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [shouldUsePolling, timerId, dispatch]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Clear countdown interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }

      // Remove overlay popover
      removeAppOverlayPopover();

      // Remove visibility change listener
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Handle countdown when timer is running
  useEffect(() => {
    // Clear any existing interval first
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    if (!activeTimer || activeTimer.status !== ETimerStatus.RUNNING) {
      return;
    }

    intervalRef.current = setInterval(() => {
      setLocalRemainingTime((prevTime) => {
        const newTime = Math.max(0, prevTime - 1);
        if (newTime <= 0 && intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          dispatch(
            setActiveTimer({
              ...activeTimer,
              remainingTimeInSeconds: 0,
              isCompleted: true,
            }),
          );
          if (!shouldUsePolling)
            dispatch(pauseTimerReqAction({ timerId: activeTimer.id || '' })).unwrap();
        }
        return newTime;
      });
    }, TIMER_INTERVAL_MS);

    // Clean up on unmount or when dependencies change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [activeTimer?.status]);

  // Add visibility change listener
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [activeTimer]);

  return (
    <Fragment>
      <div className="timer-circle-container">
        <TimerCircle
          radius={radius}
          arcLength={calculateArcLength()}
          circumference={FULL_CIRCLE_ARC}
          isInitialState={!isRunning && localRemainingTime === activeTimer?.remainingTimeInSeconds}
        />
        <div className="timer-content">
          {(activeTimer &&
            isCompleted &&
            createTimerRes.status !== EAPIStatus.PENDING &&
            showCompletedAsText) ||
          (activeTimer?.hasError && activeTimer) ? (
            <span className="timer-name-ended-title">{t('timerEndedTitle')}</span>
          ) : (
            activeTimer?.name && (
              <AppButton onClick={handleTimerNameClick} id="timer-name" className="timer-name">
                {createTimerRes.status === EAPIStatus.PENDING ? 'loading...' : activeTimer.name}
              </AppButton>
            )
          )}
          {(activeTimer &&
            isCompleted &&
            createTimerRes.status !== EAPIStatus.PENDING &&
            showCompletedAsText) ||
          (activeTimer?.hasError && showCompletedAsText && activeTimer) ? (
            <span className="timer-name-ended-description">{t('timerEndedDescription')}</span>
          ) : (
            activeTimer?.name && (
              <div className="timer-display">
                {createTimerRes.status === EAPIStatus.PENDING
                  ? 'loading...'
                  : formatTime(localRemainingTime)}
              </div>
            )
          )}
        </div>
      </div>
      <div className="timer-controls">
        {shouldShowControls && <TimerControls localRemainingTime={localRemainingTime} />}
      </div>
    </Fragment>
  );
};

export default Timer;
