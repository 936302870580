export interface ITimerData {
  id: string;
  name: string | null;
  status: ETimerStatus;
  remainingTimeInSeconds: number;
  endTime?: string;
  totalTimeInSeconds: number | null;
  isCompleted: boolean;
  hasError?: boolean;
  totalDurationInSeconds: number;
  initialDurationInSeconds: number;
}

export enum ETimerStatus {
  RUNNING = 0,
  PAUSED = 1,
}

// Base interface for timer operations that require a timer ID
export interface ITimerIdPayload {
  timerId: string;
}

export interface ICreateTimerReqPayload {
  name: string | null;
  durationInSeconds: number;
  userTaskId: string;
}

// Extend from base interface
export interface IStartTimerReqPayload extends ITimerIdPayload {}

// Extend from base interface
export interface IPauseTimerReqPayload extends ITimerIdPayload {}

// Extend from base interface and add additional properties
export interface IModifyTimerReqPayload extends ITimerIdPayload {
  secondsToAdd: number;
}

// Extend from base interface
export interface IResetTimerReqPayload extends ITimerIdPayload {}
