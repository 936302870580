import type { EnhancedStore } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import type { IAPIError } from './models';
import {
  API_ROUTES,
  loginTypeStorageKey,
  operatingSystemLocalStorageKey,
  refreshTokenLocalStorageKey,
  tokenLocalStorageKey,
} from '../../app/constants';
import type { ILoginResponse } from '../../app/auth/auth.interfaces';
import { EOperatingSystem } from '../../app/auth/auth.interfaces';
import {
  addApplicationInsightsTraces,
  addAuthorizationHeaderInterceptor,
  updatePendingIndicationToWindowOnRequest,
  updatePendingIndicationToWindowOnResponse,
  rejectionApplicationInsightTraces,
  rejectionUpdatePendingIndicationToWindow,
} from './interceptors';
import { ApplicationInsightsApi } from '../../application-insights';
import { getItemFromLocalStorage, keysExistInLocalStorage } from '../utils/localStorage.utils';
import { handleLocalLogOut, handleLogOut } from '../utils/logOut';
import { sendRefreshTokenToMobileApp } from '../../mobile-application-utils';
import { uuid } from '../utils/uuid';

export const InternalError = {
  message: 'Internal error during request.',
  code: 500,
};

let activeRefreshToken: Promise<AxiosResponse<ILoginResponse, unknown>> | null = null; // holder for one refresh token function to prevent multiple calls to refresh token
/**
 * Return the exception payload using the message and code returned from axios
 * @param ex The exception
 */
export const getExceptionPayload = (ex: unknown): IAPIError => {
  if (typeof ex !== 'object' || !ex) {
    return InternalError;
  }
  const typedException = ex as IAPIError;
  if (!!typedException?.message && !!typedException?.code) {
    return {
      message: typedException.message,
      code: typedException.code,
    };
  }
  return InternalError;
};

/**
 * The api thunk callback type (because we cannot import it from redux toolkit
 */
type ApiThunkCallback<TData, TArgs> = (args?: TArgs) => Promise<AxiosResponse<TData> | TData>;

/**
 * Create an api thunk, which will run the provided promise and await it
 * @param typePrefix The type prefix of the action
 * @param requestCallback A callback function which returns an api request using axios
 */
export const createApiThunk = <TData, TArgs = void>(
  typePrefix: string,
  requestCallback: ApiThunkCallback<TData, TArgs>,
) => {
  return createAsyncThunk<TData, TArgs, { rejectValue: IAPIError }>(
    typePrefix,
    async (args, { rejectWithValue }) => {
      try {
        const response = await requestCallback(args);
        return (response as AxiosResponse<TData>)?.data ?? (response as TData);
        // eslint-disable-next-line
      } catch (e: unknown) {
        const axiosError = e as AxiosError;
        ApplicationInsightsApi.trackException(axiosError);
        return rejectWithValue({
          message: axiosError?.message,
          code: axiosError?.status,
        });
      }
    },
  );
};

/**
 * An axios instance using our base url, and later our token
 */
// Create the main apiService with the default base URL
export const apiServiceCSharp = createAxiosInstance(`${process.env.REACT_APP_BASE_URL_CSHARP}`);

/**
 * Create an Axios instance with the specified base URL
 * @param baseURL The base URL
 */
function createAxiosInstance(baseURL: string): AxiosInstance {
  const instance = axios.create({
    baseURL,
  });

  // Add authorization header interceptor
  instance.interceptors.request.use(updatePendingIndicationToWindowOnRequest);
  instance.interceptors.request.use(
    addApplicationInsightsTraces,
    rejectionApplicationInsightTraces,
  );
  instance.interceptors.request.use(addAuthorizationHeaderInterceptor);
  // Response interceptors
  instance.interceptors.response.use(
    updatePendingIndicationToWindowOnResponse,
    rejectionUpdatePendingIndicationToWindow,
  );
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error?.response?.status;
      // we have acess to the ApiResponse
      //const {data} = error.response as IAPIRequestState<IApiResponseModel<any>>;
      // we can dispatch actions to set the error message and display errors to user
      // const {dispatch,} = store;
      const originalRequest = error?.config;
      switch (status) {
        case UNAUTHORIZED:
        case FORBIDDEN:
          ApplicationInsightsApi.trackTrace('axios - auth error state', {
            hasToken: !!localStorage.getItem(tokenLocalStorageKey),
            hasRefreshToken: !!localStorage.getItem(refreshTokenLocalStorageKey),
            requestPath: originalRequest.url,
            hasRetryFlag: originalRequest._retry,
            hasActiveRefreshTokenFuntion: !!activeRefreshToken,
          });
          ApplicationInsightsApi.trackTrace('axios - UNAUTHORIZED or FORBIDDEN');
          if (!originalRequest._requestId) {
            originalRequest._requestId = uuid();
          }
          ApplicationInsightsApi.trackTrace(
            'axios - before retry originalRequest._requestId: ' + originalRequest._requestId,
          );
          if (!originalRequest._retry) {
            ApplicationInsightsApi.trackTrace(
              'axios - retry with originalRequest._requestId: ' + originalRequest._requestId,
            );
            ApplicationInsightsApi.trackTrace('axios - originalRequest._retry = false');
            originalRequest._retry = true;
            try {
              activeRefreshToken = activeRefreshToken ? activeRefreshToken : refreshToken();
              ApplicationInsightsApi.trackTrace(
                'axios - activeRefreshToken last 4: ' + String(activeRefreshToken).slice(-4),
              );
              const res = await activeRefreshToken;
              activeRefreshToken = null;
              if (res?.data?.token && res?.data?.refreshToken) {
                ApplicationInsightsApi.trackTrace(
                  `axios - refresh token response data: token - ${res.data?.token?.slice(
                    -4,
                  )}, refreshToken - ${res.data?.refreshToken?.slice(-4)}`,
                );
                localStorage.setItem(tokenLocalStorageKey, JSON.stringify(res.data?.token));
                localStorage.setItem(
                  refreshTokenLocalStorageKey,
                  JSON.stringify(res?.data?.refreshToken),
                );
                ApplicationInsightsApi.trackTrace('axios - sending refresh token to mobile app');

                sendRefreshTokenToMobileApp({
                  token: res.data?.token,
                  refreshToken: res?.data?.refreshToken,
                });
                // delete originalRequest.headers.Authorization;
                // apiService.defaults.headers.common['Authorization'] = 'Bearer ' + res?.data?.data?.accessToken;
                ApplicationInsightsApi.trackTrace('axios - retrying original request', {
                  url: originalRequest.url,
                  hasRetryFlag: originalRequest._retry,
                  hasActiveRefreshTokenFuntion: !!activeRefreshToken,
                  tokenLastFour: localStorage.getItem(tokenLocalStorageKey)?.slice(-4),
                  refreshTokenLastFour: localStorage
                    .getItem(refreshTokenLocalStorageKey)
                    ?.slice(-4),
                  requestId: originalRequest._requestId,
                });
                return instance(originalRequest);
              } else {
                ApplicationInsightsApi.trackTrace(
                  'axios - refresh token response does not contain token or refresh token',
                );
                await handleLogOut();
                return Promise.reject(error);
              }
            } catch (error) {
              ApplicationInsightsApi.trackTrace('axios - error: ' + error);
              ApplicationInsightsApi.trackException(error);
              handleLocalLogOut();
              return Promise.reject(error);
            }
          }
          ApplicationInsightsApi.trackTrace(
            'axios - did not retry due to retry=true originalRequest._requestId: ' +
              originalRequest._requestId,
          );
          ApplicationInsightsApi.trackException(error);
          await handleLogOut();
          return Promise.reject(error);
        case SERVER_ERROR || NOT_FOUND:
          // Add dispatch to error toaster / notification
          break;
        case BAD_REQUEST:
          // Add dispatch to error toaster / notification
          break;
        case APP_BUILD_NUMBER_CHANGED:
          ApplicationInsightsApi.trackTrace(
            `Version is outdated. current client build number: ${process.env.REACT_APP_BUILD_NUMBER} - reloading.`,
          );
          window.location.reload();
          break;
        case SESSION_NOT_FOUND:
          ApplicationInsightsApi.trackTrace('Session not found. Reloading application.');
          window.location.reload();
          break;
        default:
          break;
      }
      ApplicationInsightsApi.trackException(error);
      return Promise.reject(error);
    },
  );

  return instance;
}

async function refreshToken() {
  ApplicationInsightsApi.trackTrace('refreshToken - start');
  ApplicationInsightsApi.trackTrace('refreshToken - checking localStorage state', {
    hasToken: !!localStorage.getItem(tokenLocalStorageKey),
    hasRefreshToken: !!localStorage.getItem(refreshTokenLocalStorageKey),
    hasLoginType: !!localStorage.getItem(loginTypeStorageKey),
  });
  try {
    if (
      !keysExistInLocalStorage([
        tokenLocalStorageKey,
        refreshTokenLocalStorageKey,
        loginTypeStorageKey,
      ])
    ) {
      ApplicationInsightsApi.trackTrace('refreshToken - missing keys in localstorage');
      handleLocalLogOut();
      return Promise.reject('missing keys in localstorage');
    }
    ApplicationInsightsApi.trackTrace('refreshToken - calling refresh token api');
    const loginType = getItemFromLocalStorage<string>(loginTypeStorageKey);
    const operatingSystem = getItemFromLocalStorage<string>(operatingSystemLocalStorageKey);
    const accessToken = getItemFromLocalStorage<string>(tokenLocalStorageKey);
    const refreshTokenValue = getItemFromLocalStorage<string>(refreshTokenLocalStorageKey);
    ApplicationInsightsApi.trackTrace('refreshToken - loginType: ' + loginType);
    ApplicationInsightsApi.trackTrace('refreshToken - operatingSystem: ' + operatingSystem);
    ApplicationInsightsApi.trackTrace(
      'refreshToken - accessToken last 4: ' + accessToken?.slice(-4),
    );
    ApplicationInsightsApi.trackTrace(
      'refreshToken - refreshToken last 4: ' + refreshTokenValue?.slice(-4),
    );
    return axios.post<ILoginResponse>(
      process.env.REACT_APP_BASE_URL_CSHARP + API_ROUTES.AUTH.REFRESHTOKEN + loginType,
      {
        accessToken: accessToken,
        refreshToken: refreshTokenValue,
        isFromIosMobileApp: operatingSystem === EOperatingSystem.IOS,
      },
      {
        headers: {
          'x-client-version': process.env.REACT_APP_BUILD_VERSION,
          'x-client-build-number': process.env.REACT_APP_BUILD_NUMBER,
        },
      },
    );
  } catch (error) {
    ApplicationInsightsApi.trackTrace('refreshToken - error: ' + error);
    ApplicationInsightsApi.trackException(error);
    handleLocalLogOut();
    return Promise.reject(error);
  }
}

/**
 * Injecting the store to be able to use it inside axios interceptors
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

// eslint-disable-next-line
let store: EnhancedStore | undefined;
export const injectStore = (_store: EnhancedStore) => {
  store = _store;
};
export const FORBIDDEN = 403;
export const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;
const NOT_FOUND = 404;
const BAD_REQUEST = 400;
const APP_BUILD_NUMBER_CHANGED = 900;
const SESSION_NOT_FOUND = 901;
