import type { ThunkAction, Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { sharedStoreReducer } from '../shared/store/shared.store';
import { chatReducer } from '../features/chat-wrapper/chat.store';
import { authReducer } from './auth/auth.store';
import { settingsMenuReducer } from '../shared/components/content-frame-wrapper/settings-menu/settingsMenu.store';
import { appModalsReducer } from '../shared/components/app-modals/appModals.store';
import { userReducer } from './useAppData/user.store';
import { StageTasksReducer } from '../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { StagePlannerReducer } from '../features/chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { workBlockReducer } from '../features/chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import { aiControlUXReducer } from '../features/ai-control-ux/AIControlUX.store';
import { injectStoreApplicationInsights } from '../application-insights';
import { injectStoreAxiosInterceptors } from '../shared/api/interceptors';
import { injectStoreUtils } from '../shared/utils/utils';
import { StageTimerReducer } from '../features/chat-wrapper/resizable-container/stage-container/stage-timer/stageTimer.store';
import { clearLocalTimerOnTaskDeleteMiddleware } from './storeMiddleWare';

export const rootReducer = {
  sharedStoreReducer,
  chatReducer,
  authReducer,
  settingsMenuReducer,
  userReducer,
  appModalsReducer,
  StageTasksReducer,
  StagePlannerReducer,
  workBlockReducer,
  aiControlUXReducer,
  StageTimerReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(clearLocalTimerOnTaskDeleteMiddleware),
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
injectStoreApplicationInsights(store);
injectStoreAxiosInterceptors(store);
injectStoreUtils(store);
